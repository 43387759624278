import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AuthStateModel } from '../model/auth-state.model';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { LoginAction } from './action/login.action';
import { HttpClient } from '@angular/common/http';
import { environment } from '@citydeals/env';
import { catchError, tap, throwError } from 'rxjs';
import { SocialLoginAction } from './action/social-login.action';
import { OldToastService } from '@citydeals/feature/old-toast';
import { UserEntity } from '../entity/user.entity';
import { LogoutAction } from './action/logout.action';
import { deepCopy, isNil, isString } from '@citydeals/common';
import { ReloadUserDataAction } from './action/reload-user-data.action';
import { RegisterAction } from './action/register.action';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResetPasswordAction } from './action/reset-password.action';
import { UpdateUserAction } from './action/update-user.action';
import { LoginWithTokenAction } from './action/login-with-token.action';
import { isPlatformBrowser } from '@angular/common';

const defaultState: AuthStateModel = {
  isLoggedIn: false,
  type: null,
  token: null,
  user: null,
};

export const authStateName = 'citydeals_auth';
@State<AuthStateModel>({
  name: authStateName,
  defaults: { ...defaultState },
})
@Injectable()
export class AuthState implements NgxsOnInit {
  @Selector()
  @ImmutableSelector()
  static isLoggedIn(state: AuthStateModel): boolean {
    return state.isLoggedIn;
  }

  @Selector()
  @ImmutableSelector()
  static currentUser(state: AuthStateModel) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return state.user!;
  }

  @Selector()
  @ImmutableSelector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  constructor(
    private http: HttpClient,
    private oldToastService: OldToastService,
    private matSnackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  ngxsOnInit(ctx?: StateContext<any>) {
    if (
      isPlatformBrowser(this.platformId) &&
      !isNil(ctx) &&
      ctx.getState().token !== null
    ) {
      ctx.dispatch(new ReloadUserDataAction());
    }
  }

  @Action(LoginAction)
  @ImmutableContext()
  login(
    { setState }: StateContext<AuthStateModel>,
    { payload, userType }: LoginAction
  ) {
    return this.http
      .post<{
        token: string;
        user: UserEntity;
      }>(`${environment.backendUrl}/loginclient`, payload)
      .pipe(
        tap((response) => {
          setState((state: AuthStateModel) => {
            state.isLoggedIn = true;
            state.token = response.token;
            state.user = response.user;
            return state;
          });
        }),
        catchError((error) => {
          if (isString(error.error.message)) {
            this.oldToastService.show({
              text: [error.error.message],
              type: 'warning',
            });
          } else if (error.error.errors) {
            this.oldToastService.show({
              text: error.errors,
              type: 'warning',
            });
          } else {
            this.oldToastService.show({
              text: error.error,
              type: 'warning',
            });
          }
          return throwError(error);
        })
      );
  }

  @Action(SocialLoginAction)
  @ImmutableContext()
  socialLogin(
    state: StateContext<AuthStateModel>,
    { provider }: SocialLoginAction
  ) {
    return this.http
      .get<{ data: { redirect: string } }>(
        `${environment.backendUrl}/auth/redirect/${provider}`
      )
      .pipe(tap((response) => (window.location.href = response.data.redirect)));
  }

  @Action(LogoutAction)
  @ImmutableContext()
  logout({ setState }: StateContext<AuthStateModel>) {
    return this.http.post(`${environment.backendUrl}/logout`, '').pipe(
      tap(() => {
        setState((state: AuthStateModel) => {
          return deepCopy(defaultState);
        });
      })
    );
  }

  @Action(ReloadUserDataAction)
  @ImmutableContext()
  reloadUserData({ setState }: StateContext<AuthStateModel>) {
    return this.http.get<UserEntity>(`${environment.backendUrl}/userdata`).pipe(
      tap((response) => {
        setState((state: AuthStateModel) => {
          state.user = response;
          return state;
        });
      })
    );
  }

  @Action(RegisterAction)
  @ImmutableContext()
  register(
    { setState }: StateContext<AuthStateModel>,
    { payload, userType }: RegisterAction
  ) {
    return this.http
      .post<UserEntity>(`${environment.backendUrl}/client/register`, payload)
      .pipe(
        catchError((error) => {
          const message = error.error.errors ? error.errors : error.error;
          this.matSnackBar.open(message, undefined, {
            panelClass: ['snackBarStyleForError'],
            duration: 2000,
          });

          return throwError(error);
        })
      );
  }

  @Action(ResetPasswordAction)
  @ImmutableContext()
  resetPassword(
    { setState }: StateContext<AuthStateModel>,
    { payload }: ResetPasswordAction
  ) {
    return this.http.post<UserEntity>(
      `${environment.backendUrl}/password/reset`,
      payload
    );
  }

  @Action(UpdateUserAction)
  @ImmutableContext()
  updateUser(
    { setState }: StateContext<AuthStateModel>,
    { payload }: UpdateUserAction
  ) {
    return this.http
      .post<UserEntity>(`${environment.backendUrl}/profilchange`, payload)
      .pipe(
        tap(() => {
          this.matSnackBar.open('Az adatait sikeresen módosította', undefined, {
            panelClass: ['snackBarStyleForSave'],
            duration: 2000,
          });
        }),
        catchError((error) => {
          const message = isNil(error.error.errors['password'])
            ? error.error.message
            : 'A jelenlegi jelszó hibás';
          this.matSnackBar.open(message, undefined, {
            panelClass: ['snackBarStyleForError'],
            duration: 2000,
          });
          return throwError(() => new Error(message));
        })
      );
  }

  @Action(LoginWithTokenAction)
  @ImmutableContext()
  loginWithToken(
    { setState }: StateContext<AuthStateModel>,
    { token, userType }: LoginWithTokenAction
  ) {
    setState((state: AuthStateModel) => {
      state.token = token;
      state.isLoggedIn = true;
      state.type = userType;
      return state;
    });
  }
}
