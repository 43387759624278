import { TiktokEvent } from '../models/tiktok-event.model';
import { EventProductTiktok } from '../models/event-product.model';
import { BaseEventTiktok } from './base-tiktok-event';


export class ProductPageTiktokEvent extends BaseEventTiktok implements TiktokEvent {
    constructor(product: EventProductTiktok) {
        super('ViewContent', product);
    }
}
