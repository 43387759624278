import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrackService } from './track.service';
import { TiktokTrackService } from './tiktok-track.service';

@NgModule({
  providers: [TrackService, TiktokTrackService],
  imports: [CommonModule],
})
export class TrackModule {}
