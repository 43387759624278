import { AnalitycsEvent } from '../models/analytics-event.model';
import { CitydealsEvent } from '../models/citydeals-event.model';
import { EventProduct } from '../models/event-product.model';

export class AddToCartEvent implements CitydealsEvent {
  static eventName = 'addToCart';
  private product: EventProduct;

  constructor(product: EventProduct) {
    this.product = product;
  }

  getEvent(): AnalitycsEvent {
    return {
      event: AddToCartEvent.eventName,
      ecommerce: {
        currencyCode: 'HUF',
        add: {
          products: [this.product],
        },
      },
    };
  }
}
