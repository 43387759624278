<div
  class="toast"
  [@fadeAnimation]="{
    value: animationState,
    params: {
      fadeIn: toastConfig.animation?.fadeIn ?? 200,
      fadeOut: toastConfig.animation?.fadeOut ?? 200
    }
  }"
  (@fadeAnimation.done)="onFadeFinished($event)"
>
  <div class="modal-header">
    <div class="forgottenPwContent">
      <h5 class="modal-title">Hiba!</h5>
    </div>
    <button
      type="button"
      class="modalClose"
      (click)="close()"
      data-dismiss="modal"
    >
      <i class="icon icon-close3"></i>
    </button>
  </div>
  <div>
    <ul>
      <li
        style="margin-top: 5px !important"
        class="kisfelirat"
        *ngFor="let error of hibalista"
      >
        {{ error }}
      </li>
    </ul>
  </div>

  <!--    <mat-icon (click)="close()">close</mat-icon>-->
</div>
