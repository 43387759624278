import { AnalitycsEventEnhancedConversation } from '../models/analytics-event.model';
import { AnalitycsEvent } from '../models/analytics-event.model';
import { CitydealsEvent } from '../models/citydeals-event.model';
// import { AddToCartEvent } from './add-to-cart.event';
import { PageInitEvent } from './page-init.event';

export class ThankYouEnhancedConversionsEvent extends PageInitEvent implements CitydealsEvent {
    private email: string;
    private phoneNumber: string;
    private firstName: string;
    private lastName: string;
    private address: object;

    constructor(
        userId: number | null,
        email: string,
        phoneNumber: string,
        firstName: string,
        lastName: string,
        street: string,
        city: string,
        region: string,
        postalCode: string,
        country: string
    ) {
        super('enhanced_conversions', userId);
        this.email = email;
        this.phoneNumber = phoneNumber,
        this.firstName = firstName,
        this.lastName = lastName,
        this.address = {
            street: street,
            city: city,
            region: region,
            postalCode: postalCode,
            country: country
        }
    }

    override getEvent(): AnalitycsEventEnhancedConversation {
        return {
            // ...super.getEvent(),
            event: this.pageType,
            pageType: this.pageType,
            userId: this.userId,
            // ...{
            //     event: this.pageType,
            //     pageType: this.pageType,
            //     userId: this.userId,
            // },
            //event: 'conversion',
            email: this.email,
            phone_number: this.phoneNumber,
            first_name: this.firstName,
            last_name: this.lastName,
            address: this.address
        };
    }
}
