import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isNil } from '@citydeals/common';
import { CitydealsEvent } from './models/citydeals-event.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class TrackService {
  private run: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.run = isPlatformBrowser(platformId);
  }

  track(event: CitydealsEvent) {
    if (this.run === false) {
      return;
    }
    const dataLayer = (window as { dataLayer?: unknown[] }).dataLayer;
    if (isNil(dataLayer)) {
      console.error('The Google Tag Manager is not registered');
      return;
    }
    dataLayer.push(event.getEvent());
  }
}
