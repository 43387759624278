import {Inject, Injectable, Optional, PLATFORM_ID} from "@angular/core";
import {Action, NgxsOnInit, Selector, State, StateContext} from "@ngxs/store";
import {OccasionEntity} from "../entity/occasion.entity";
import {HttpClient} from "@angular/common/http";
import {IS_BOT} from "@citydeals/common";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {ImmutableContext, ImmutableSelector} from "@ngxs-labs/immer-adapter";
import {GetOccasionAction} from "./action/get-occasion.action";
import {environment} from "@citydeals/env";
import {tap} from "rxjs";

interface OccasionStateModel {
  occasionList: OccasionEntity[] | null;
  loadingOccasionList: boolean;
}

const defaultState = { loadingOccasionList: true, occasionList: null };

@State<OccasionStateModel>({
  name: 'citydeals_occasion',
  defaults: defaultState,
})
@Injectable()
export class OccasionState implements NgxsOnInit {
  @Selector()
  @ImmutableSelector()
  static loadingOccasionList(state: OccasionStateModel): boolean {
    return state.loadingOccasionList;
  }

  @Selector()
  @ImmutableSelector()
  static occasionList(state: OccasionStateModel): any[] {
    return state.occasionList ?? [];
  }

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    @Optional() @Inject(IS_BOT) private readonly isBot = false
  ) {}

  ngxsOnInit(ctx: StateContext<OccasionStateModel>) {
    if (
      isPlatformBrowser(this.platformId) ||
      (isPlatformServer(this.platformId) && this.isBot === true)
    ) {
      // simple load
      ctx.dispatch(new GetOccasionAction());
    }
  }

  @Action(GetOccasionAction)
  @ImmutableContext()
  getOccasions({ setState, getState }: StateContext<OccasionStateModel>) {
    setState((state: OccasionStateModel) => {
      state.loadingOccasionList = true;
      return state;
    });
    return this.http
      .get<OccasionEntity[]>(
        `${environment.backendUrl}/client/floatingsearchbox/occasions`
      )
      .pipe(
        tap((response) => {
          setState((state: OccasionStateModel) => {
            state.occasionList = response;
            state.loadingOccasionList = false;
            return state;
          });
        })
      );
  }
}
