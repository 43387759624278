import { AnalitycsEvent } from '../models/analytics-event.model';
import { CitydealsEvent } from '../models/citydeals-event.model';
import { EventProduct } from '../models/event-product.model';
import { PageInitEvent } from './page-init.event';

export class CheckoutEvent extends PageInitEvent implements CitydealsEvent {
  private step: number;
  private products: EventProduct[];

  constructor(userId: number | null, step: number, products: EventProduct[]) {
    super('checkout', userId);
    this.step = step;
    this.products = products;
  }

  override getEvent(): AnalitycsEvent {
    return {
      ...super.getEvent(),
      ecommerce: {
        currencyCode: 'HUF',
        actionField: {
          step: this.step,
        },
        checkout: {
          products: this.products,
        },
      },
    };
  }
}
