import { Pipe, PipeTransform } from '@angular/core';
import { isNumeric } from '../../typeguard';

@Pipe({
  name: 'discount',
})
export class DiscountPipe implements PipeTransform {
  transform(value: number | undefined, type = false): string {
    return isNumeric(value)
      ? `-${Math.round(value)} ${type ? 'Ft' : '%'}`
      : `${value}`;
  }
}
