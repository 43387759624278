import { Environment } from './environment.interface';
import { version } from './version';

export const environment: Environment = {
  production: true,
  version: version,
  appUrl: 'https://dev.citydeals.hu',
  extraPlugins: [],
  backendUrl: 'https://dev.citydeals.hu/api',
  backendStorageBaseUrl: 'https://dev.citydeals.hu',
  partnerAdminUrl: 'https://partner.citydeals.hu',
  recaptchaSiteKey: '6Lfv494ZAAAAADz1hYsGX8caT8Wv0Zt2aFAeK9Bs',
  trustTokenMainPageURL: 'https://cdn.trustindex.io/loader.js?630b91315bb24101a986c65398b',
  trustTokenCategoryURL: 'https://cdn.trustindex.io/loader.js?630b91315bb24101a986c65398b',
  trustTokenBasketURL: 'https://cdn.trustindex.io/loader.js?630b91315bb24101a986c65398b',
  cookieBotAPIKey: 'ZXVWaWlTRkdBZWRqaDBra010NFdpeWRIQSswYVVsSTdsL3p5YTVRMnRzK05XM1JENVVaQjZnPT0=',
  cookieBotDomainGroupId: '994c1263-0a9d-463d-abbd-3fae19dca5ef',
  hereApiKey: '8XStj-ZuGpAppIFby-l_3P6kIybmUGpuDpQZ2RHN-5E'
};
