<div class="d-flex justify-content-between align-items-start">
  <h5 mat-dialog-title>Sikeres Regisztráció</h5>
  <button class="cd-btn--no-style" mat-dialog-close>
    <i class="icon icon-close"></i>
  </button>
</div>
<mat-dialog-content>
  <p>
    Sikeresen regisztráltál. A regisztráció befejezéséhez kérlek, nyisd meg az
    e-mail címedre küldött levelünket és kattints az e-mail cím megerősítése
    gombra. Ha hamarosan nem kapsz tőlünk e-mail-t, kérlek ellenőrizd a
    "Levélszemét' könyvtárad, vagy módosíts a spam szűrőd beállításain.
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="cd-btn cd-btn--green cd-btn-rounded w-100" mat-dialog-close>
    OK
  </button>
</mat-dialog-actions>
