<a class="tabletLogo"
  ><img
    src="../assets/img/citydeals_logo.png"
    alt="Citydeals logo"
    [routerLink]="['/']"
/></a>
<div class="loginContent" *transloco="let t;">
  <h1>{{ t('PAGE.LOGIN.FORM.HEADER_TEXT') }}</h1>
  <div class="social-login-box">
    <button
      type="button"
      class="btn white-btn fbBtn"
      (click)="socialLogin('facebook')"
    >
      <img src="../assets/img/facebook.svg" alt="facebook login" /><span
        >{{ t('PAGE.COMMON.LOGIN_WITH_FACEBOOK_ACCOUNT') }}</span
      >
    </button>
    <button
      type="button"
      class="btn white-btn googleBtn"
      (click)="socialLogin('google')"
    >
      <img src="../assets/img/google.svg" alt="google login" /><span
        >{{ t('PAGE.COMMON.LOGIN_WITH_GOOGLE_ACCOUNT') }}</span
      >
    </button>
  </div>
  <div class="separator-box">
    <hr />
    <span>{{ t('PAGE.COMMON.OR') }}</span>
  </div>
  <div class="form-box">
    <h6>{{ t('PAGE.COMMON.PLEASE_GIVE_ME_YOUR_DATA') }}</h6>
    <form
      id="clientLoginForm"
      [formGroup]="clientLoginForm"
      (ngSubmit)="onSubmit()"
    >
      <fieldset>
        <div class="input-box">
          <input
            type="email"
            name="email"
            placeholder="{{ t('PAGE.COMMON.EMAIL_ADDRESS') }}"
            formControlName="email"
            class="monster"
          />
        </div>
        <div class="input-box">
          <!-- <i class="pwVisibilityToggler icon icon-eye" style="font-size: unset!important;"></i> -->
          <input
            type="password"
            name="password"
            placeholder="{{ t('PAGE.COMMON.PASSWORD') }}"
            formControlName="password"
            class="monster"
            [type]="hide ? 'password' : 'text'"
          />
          <mat-icon matSuffix (click)="hide = !hide" class="eye-class">{{
            hide ? 'visibility_off' : 'visibility'
          }}</mat-icon>
        </div>
      </fieldset>
      <div class="forgotten-pw-box">
        <a
          (click)="openModal()"
          class="forgottenPwLink openForgottenPw forgotten-title"
          >{{ t('PAGE.LOGIN.FORM.FORGOTTEN_PASSWORD') }}</a
        >
      </div>
      <div class="checkbox-container">
        <input
          type="checkbox"
          name="stay_logined"
          id="stayLoginedChbClient"
          class="checkbox-input"
          formControlName="stayLoggedIn"
        />
        <label for="stayLoginedChbClient" class="checkbox-label"
          ><div><i class="icon icon-check"></i></div>
          {{ t('PAGE.LOGIN.FORM.STAY_LOGGEDIN') }}</label
        >
      </div>
      <button
        type="submit"
        class="cd-btn cd-btn--rounded cd-btn--green login-btn"
      >
        {{ t('PAGE.COMMON.LOGIN') }}
      </button>
    </form>
  </div>
  <a [href]="partnerUrl" class="change-page-link">{{ t('PAGE.COMMON.PARTNER_LOGIN') }}</a>
  <div class="to-login-or-reg-page-box">
    <a routerLink="/auth/registration" class="cd-btn--no-style"
      >{{ t('PAGE.LOGIN.FORM.HAVE_NOT_REGISTERED_YET') }}</a
    >
    <a
      routerLink="/auth/registration"
      class="cd-btn cd-btn--rounded cd-btn--green"
      style="border-radius: 20px !important"
      >{{ t('PAGE.COMMON.REGISTRATION') }}</a
    >
  </div>
</div>
