import { BasketCouponPackageModel } from './basket-coupon-package.model';

export enum CouponType {
  GLOBAL= 'GLOBAL',
  PACKAGE= 'PACKAGE'
}

export interface BasketCouponModel {
  code: string;
  created_at: string;
  discount: number;
  id: number;
  name: string;
  status: boolean;
  updated_at: string;
  valid_end: string;
  valid_from: string;
  coupon_type: CouponType;
  packages: BasketCouponPackageModel[];
}
