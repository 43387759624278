<a class="tabletLogo"
  ><img
    src="../assets/img/citydeals_logo.png"
    alt="Citydeals logo"
    [routerLink]="['/']"
/></a>
<div class="regContent" *transloco="let t; read: 'PAGE'">
  <h1>{{ t('REGISTRATION.FORM.HEADER_TEXT') }}</h1>
  <div class="social-login-box">
    <button
      type="button"
      class="btn white-btn fbBtn"
      (click)="socialLogin('facebook')"
    >
      <img src="../assets/img/facebook.svg" alt="facebook login" /><span
        >{{ t('COMMON.LOGIN_WITH_FACEBOOK_ACCOUNT') }}</span
      >
    </button>
    <button
      type="button"
      class="btn white-btn googleBtn"
      (click)="socialLogin('google')"
    >
      <img src="../assets/img/google.svg" alt="google login" /><span
        >{{ t('COMMON.LOGIN_WITH_GOOGLE_ACCOUNT') }}</span
      >
    </button>
  </div>
  <div class="separator-box">
    <hr />
    <span>{{ t('COMMON.OR') }}</span>
  </div>
  <div class="form-box">
    <h6>{{ t('COMMON.PLEASE_GIVE_ME_YOUR_DATA') }}</h6>
    <form id="regForm" [formGroup]="clientRegisterForm" (ngSubmit)="onSubmit()">
      <fieldset>
        <div class="input-box mb-3">
          <input
            type="checkbox"
            name="companyRegistration"
            id="companyRegistration"
            class="checkbox-input"
            formControlName="companyRegistration"
          />
          <label for="companyRegistration" class="checkbox-label">
            <div><i class="icon icon-check"></i></div>
            {{ t('REGISTRATION.FORM.REGISTRATION_AS_COMPANY') }}
          </label>
        </div>
        <div
          class="inputRow"
          [ngStyle]="{
            'margin-bottom':
              !hasError('companyName', 'pattern') &&
              !hasError('taxNumber', 'pattern')
                ? '20px'
                : '10px',
            visibility: isCompanyRegistration() ? 'visible' : 'hidden'
          }"
          *ngIf="isCompanyRegistration()"
        >
          <div class="input-box">
            <input
              type="text"
              name="companyName"
              placeholder="{{t('REGISTRATION.FORM.COMPANY_NAME')}}"
              formControlName="companyName"
            />
          </div>
          <div class="input-box">
            <input
              type="text"
              name="taxNumber"
              placeholder="{{t('REGISTRATION.FORM.COMPANY_TAX_NUMBER')}}"
              formControlName="taxNumber"
            />
          </div>
        </div>

        <div
          class="inputRow"
          [ngStyle]="{
            'margin-bottom':
              !hasError('lastName', 'pattern') &&
              !hasError('firstName', 'pattern')
                ? '20px'
                : '10px'
          }"
        >
          <div class="input-box">
            <input
              type="text"
              #lastName
              name="lastname"
              placeholder="{{t('COMMON.FIRST_NAME')}}"
              formControlName="lastName"
            />
          </div>

          <div class="input-box">
            <input
              type="text"
              name="firstname"
              placeholder="{{t('COMMON.LAST_NAME')}}"
              formControlName="firstName"
            />
          </div>
        </div>

        <div
          class="inputRow"
          [hidden]="
            !hasError('lastName', 'pattern') &&
            !hasError('firstName', 'pattern')
          "
        >
          <div class="input-box">
            <mat-error *ngIf="hasError('firstName', 'pattern')"
              >{{ t('COMMON.VALIDATION_TEXT.FIRST_NAME_FORMAT') }}
            </mat-error>
            <mat-error
              *ngIf="
                hasError('lastName', 'pattern') &&
                !hasError('firstName', 'pattern')
              "
              >{{ t('COMMON.VALIDATION_TEXT.FIRST_NAME_FORMAT') }}
            </mat-error>
          </div>
        </div>

        <div class="inputRow">
          <div class="input-box">
            <input
              formControlName="birthdate"
              [matDatepicker]="picker"
              (click)="picker.open()"
              placeholder="{{ t('REGISTRATION.FORM.BIRTH_DATE') }}"
            />
            <mat-datepicker #picker></mat-datepicker>
            <i
              class="pwVisibilityToggler2 icon icon-calendar"
              (click)="picker.open()"
              style="font-size: unset !important"
            ></i>
          </div>
        </div>
        <!--                <button mat-raised-button (click)="picker.open()">Open</button>-->
        <div class="inputRow">
          <div class="input-box">
            <input
              type="email"
              name="email"
              placeholder="{{ t('COMMON.EMAIL_ADDRESS') }}"
              formControlName="email"
            />
            <div
              *ngIf="hasError('email', 'email')"
              class="inputRow"
              [ngStyle]="{
                'margin-top': hasError('email', 'email') ? '10px' : '0px'
              }"
            >
              <div class="input-box">
                <mat-error *ngIf="hasError('email', 'email')"
                  >{{ t('VALIDATION_TEXT.INVALID_EMAIL_FORMAT') }}
                </mat-error>
              </div>
            </div>
            <!--                    <mat-error *ngIf="hasError('email','pattern')">Az email cím nem tartalmazhat ékezetet.-->
            <!--                        Megengedett-->
            <!--                        karakterek: @, . , -, _. Helyes formátum: email@email.hu-->
            <!--                    </mat-error>-->
          </div>
        </div>
        <div class="inputRow">
          <div class="input-box">
            <!-- <i class="pwVisibilityToggler icon icon-eye" style="font-size: unset!important"></i> -->
            <input
              type="password"
              name="password"
              placeholder="{{ t('COMMON.PASSWORD') }}"
              formControlName="password"
              class="monster"
              [type]="hide ? 'password' : 'text'"
            />
            <mat-icon matSuffix (click)="hide = !hide" class="eye-class">{{
              hide ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </div>
        </div>
        <div class="inputRow">
          <div class="input-box">
            <!-- <i class="pwVisibilityToggler icon icon-eye" style="font-size: unset!important"></i> -->
            <input
              type="password"
              name="password_re"
              placeholder="{{ t('COMMON.CONFIRM_PASSWORD') }}"
              formControlName="rePassword"
              class="monster rePass"
              [type]="rehide ? 'password' : 'text'"
            />
            <mat-icon matSuffix (click)="rehide = !rehide" class="eye-class">{{
              rehide ? 'visibility_off' : 'visibility'
            }}</mat-icon>
          </div>
        </div>
        <div
          class="input-box"
          [ngStyle]="{ 'margin-top': !password() ? '0px' : '-10px' }"
        >
          <mat-error *ngIf="hasError('password', 'pattern')"
            >{{ t('VALIDATION_TEXT.INVALID_PASSWORD_FORMAT') }}
          </mat-error>
          <mat-error
            *ngIf="
              !hasError('password', 'pattern') &&
              password() &&
              !clientRegisterForm.get('rePassword')?.pristine
            "
            >{{ t('VALIDATION_TEXT.PASSWORDS_NOT_MATCH') }}
          </mat-error>
        </div>
        <div class="inputRow">
          <div class="input-box">
            <input
              type="text"
              name="zipcode"
              placeholder="{{ t('REGISTRATION.FORM.ZIPCODE') }}"
              formControlName="zipcode"
            />
          </div>
          <div class="input-box">
            <input
              type="text"
              name="settlement"
              placeholder="{{ t('REGISTRATION.FORM.SETTLEMENT') }}"
              formControlName="settlement"
            />
          </div>
        </div>
        <div class="inputRow">
          <!-- <div class="input-box">
                        <input
                            type="text"
                            name="addressType"
                            placeholder="Cím 2"
                            formControlName="addressType"
                        />
                    </div> -->
          <div class="input-box">
            <input
              type="text"
              name="addressName"
              placeholder="{{ t('REGISTRATION.FORM.ADDRESS') }}"
              formControlName="addressName"
            />
          </div>
          <div class="input-box">
            <input
              type="text"
              name="addressNum"
              placeholder="{{ t('REGISTRATION.FORM.ADDRESS_NUM') }}"
              formControlName="addressNum"
            />
          </div>
        </div>
      </fieldset>

      <div class="input-box" style="margin-top: 20px">
        <input
          type="checkbox"
          name="newsletter"
          id="newsletterChb"
          class="checkbox-input"
          formControlName="newsletter"
        />
        <label for="newsletterChb" class="checkbox-label">
          <div><i class="icon icon-check"></i></div>
          {{ t('REGISTRATION.FORM.SUBSCRIBE_NEWSLETTER') }}</label
        >
      </div>
      <div class="mt-2">
        <input
          type="checkbox"
          name="aszf"
          id="acceptAszfChb"
          class="checkbox-input"
          formControlName="privacyPolicy"
        />
        <label for="acceptAszfChb" class="checkbox-label">
          <div><i class="icon icon-check"></i></div>
          {{ t('REGISTRATION.FORM.CONFIRM_PRIVACY_POLICY_P1') }}
          <a [routerLink]="['/page/aszf']">{{ t('REGISTRATION.FORM.CONFIRM_PRIVACY_POLICY_P2') }}</a> {{ t('REGISTRATION.FORM.CONFIRM_PRIVACY_POLICY_P3') }}
          <a [routerLink]="['/page/adatvedelmi-nyialtkozat']"
            >{{ t('REGISTRATION.FORM.CONFIRM_PRIVACY_POLICY_P4') }}</a
          >.<span style="color: red">*</span></label
        >
      </div>
      <div class="center">
        <ngx-recaptcha2
         *ngIf="isBrowser"
          style="border-left: unset !important"
          #captchaElem
          [siteKey]="siteKey"
          (reset)="handleReset()"
          (expire)="handleExpire()"
          (load)="handleLoad()"
          (success)="handleSuccess($event)"
          [useGlobalDomain]="false"
          [size]="size"
          hl="{{ t('COMMON.RECAPTCHA_HL') }}"
          [theme]="theme"
          [type]="type"
          formControlName="recaptcha"
        >
        </ngx-recaptcha2>
      </div>
      <div class="center">
        <button
          [disabled]="clientRegisterForm.invalid"
          type="submit"
          class="cd-btn cd-btn--green cd-btn--rounded register-btn"
          [ngClass]="clientRegisterForm.valid ? 'greenBtn' : 'greyBtn'"
          style="border-radius: 20px !important"
        >
          {{ t('COMMON.REGISTRATION') }}
        </button>
      </div>
    </form>
  </div>
</div>
<div class="to-login-or-reg-page-box" *transloco="let t; read: 'PAGE'">
  <span>{{ t('REGISTRATION.FORM.REGISTERED_ALREADY') }} </span>
  <a
    routerLink="/auth/login"
    class="cd-btn cd-btn--green cd-btn--rounded"
    style="border-radius: 20px !important"
    >{{ t('COMMON.LOGIN') }}</a
  >
</div>
<ngx-spinner color="#BF5748" size="medium" type="ball-spin-clockwise-fade" *transloco="let t;">
  <p style="font-size: 20px; color: white">{{ t('PAGE.COMMON.SPINNER_TEXT') }}</p>
</ngx-spinner>
