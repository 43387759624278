import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { BasketStateModel } from './model/basket-state.model';
import { AddItemToBasketAction } from './action/add-item-to-basket.action';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { RemoveItemFromBasketAction } from './action/remove-item-from-basket.action';
import { ClearBasketAction } from './action/clear-basket.action';
import { deepCopy, isNil, isNumeric } from '@citydeals/common';
import { BasketOfferModel } from './model/basket-offer.model';
import { BasketPriceModel } from './model/basket-price.model';
import {BasketCouponModel, CouponType} from './model/basket-coupon.model';
import { SaveBasketOverviewFormAction } from './action/save-basket-overview-form.action';
import { BasketOverviewFormModel } from './model/basket-overview-form.model';
import { InitBasketAction } from './action/init-basket.action';
import { ValidateBasketCouponAction } from './action/validate-basket-coupon.action';
import { HttpClient } from '@angular/common/http';
import {
  AddToCartEvent,
  AddToCartTiktokEvent,
  CheckoutEvent,
  RemoveFromCartEvent,
  TrackService,
  TiktokTrackService,
  ThankYouEnhancedConversionsEvent,
} from '@citydeals/track';
import { RemoveCouponAction } from './action/remove-coupon.action';
import { environment } from '@citydeals/env';
import { filter, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
  AuthState,
  LoginAction,
  LoginWithTokenAction,
  LogoutAction,
} from '@citydeals/auth';
import { LoadBasketRemoteStateAction } from './action/load-basket-remote-state.action';
import { BasketAddressFormModel } from './model/basket-address-form.model';
import { SaveBasketAddressFormAction } from './action/save-basket-address-form.action';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrencyPipe } from '@angular/common';

// const defaultsMock: BasketStateModel = {
//   offers: [
//     {
//       discountType: true,
//       discountValue: 1234,
//       picture: {
//         big: '/storage/offerImages/448/nagy-djpg-5503.jpg',
//         medium: '',
//         small: '',
//         alt: 'Cat',
//       },
//       selected_slug: 'elmeny',
//       slug: 'nagy-durranas-csomag-elmenyloveszet-448',
//       voucherTitle: 'Nagy durranás csomag: Élménylövészet',
//       price: 12000,
//       quantity: 4,
//       id: 23112,
//       idx: 'absdfsda-234123r2232424weq',
//       finalPrice: 12000,
//       discountPrice: 10000,
//       selectedDate: { id: null, date: null },
//       selectedHour: { id: null, time: null },
//       available_quantity: 21000,
//       offerId: 312312,
//       commision: '20.32',
//       created_at: '',
//       excelName: null,
//       originalPrice: 22000,
//       packageDate: [],
//     },
//     {
//       discountType: true,
//       discountValue: 1234,
//       picture: {
//         big: '/storage/offerImages/1928/870-cloned-1605889278-7281-cloned-1602176521-6403-cloned-1597332988-5570-1593710702-8115jpg-9965.jpg',
//         medium: '',
//         small: '',
//         alt: 'Cat',
//       },
//       selected_slug: 'elmeny',
//       slug: 'nagy-durranas-csomag-elmenyloveszet-448',
//       voucherTitle: 'Gasztro-wellness csomag a Rudas Bistrótól 1 fő részére',
//       price: 11990,
//       quantity: 3,
//       id: 24210,
//       idx: 'absdfsda-2341234weq',
//       finalPrice: 11990,
//       discountPrice: 8000,
//       selectedDate: { id: null, date: null },
//       selectedHour: { id: null, time: null },
//       available_quantity: 21000,
//       offerId: 312312,
//       commision: '20.32',
//       created_at: '',
//       excelName: null,
//       originalPrice: 19990,
//       packageDate: [],
//     },
//   ],
//   coupon: null,
//   steps: [
//     {
//       route: BASKET_ROUTE.overview,
//       position: 1,
//       name: 'Kosár megtekintése',
//       disabled: false,
//     },
//     {
//       route: BASKET_ROUTE.address,
//       position: 2,
//       name: 'Bejelentkezés / Regisztráció',
//       disabled: false,
//     },
//     {
//       route: BASKET_ROUTE.paymentMethod,
//       position: 3,
//       name: 'Fizetési mód kiválasztása',
//       disabled: true,
//     },
//   ],
//   activeStepPosition: 1,
//   overviewForm: { gifts: {}, couponCode: '' },
// };

const defaultState: BasketStateModel = {
  offers: [],
  coupon: null,
  overviewForm: { gifts: {}, couponCode: '' },
  addressForm: null,
};
export const basketStateName = 'citydeals_basket';
@State<BasketStateModel>({
  name: basketStateName,
  defaults: defaultState,
})
@Injectable()
export class BasketState implements NgxsOnInit {
  private afterLogin = false;
  @Selector()
  static offersCount(state: BasketStateModel): number {
    return state.offers?.length ?? 0;
  }

  @Selector()
  static offers(state: BasketStateModel): BasketOfferModel[] {
    return state.offers;
  }

  @Selector()
  static price(state: BasketStateModel): BasketPriceModel {
    let total = 0;
    let discount = 0;
    let couponDiscount = 0;

    for (const offer of state.offers) {
      total += offer.finalPrice;

      if (!isNil(state.coupon) && state.coupon.coupon_type === CouponType.PACKAGE &&
        state.coupon.packages.some(pkg => pkg.id === offer.id)) {
        couponDiscount += offer.finalPrice * offer.quantity * (state.coupon.discount / 100);
      }

      discount += offer.originalPrice * offer.quantity * (offer.discountValue / 100);
    }

    if (!isNil(state.coupon) && state.coupon.coupon_type === CouponType.GLOBAL) {
      couponDiscount = Math.round((total / 100) * state.coupon.discount);
    }

    total -= couponDiscount;
    discount += couponDiscount;
    return { total, discount };
  }

  @Selector()
  static coupon(state: BasketStateModel): BasketCouponModel | null {
    return state.coupon;
  }

  @Selector()
  static overviewForm(state: BasketStateModel): BasketOverviewFormModel {
    return state.overviewForm;
  }

  @Selector()
  static addressForm(state: BasketStateModel): BasketAddressFormModel | null {
    return state.addressForm;
  }

  constructor(
    private trackService: TrackService,
    private tiktokTrackService: TiktokTrackService,
    private http: HttpClient,
    private store: Store,
    private matSnackBar: MatSnackBar
  ) {}

  ngxsOnInit(ctx?: StateContext<any>) {
    this.store
      .select(AuthState.isLoggedIn)
      .pipe(filter((isLoggedIn) => isLoggedIn))
      .subscribe(() => this.store.dispatch(new LoadBasketRemoteStateAction()));
  }

  @Action([LoginAction, LoginWithTokenAction])
  loginAction() {
    this.afterLogin = true;
  }

  @Action(LoadBasketRemoteStateAction)
  @ImmutableContext()
  loadBasketRemoteState({
    setState,
    getState,
    dispatch,
  }: StateContext<BasketStateModel>) {
    const prevState = deepCopy(getState());
    return this.http
      .get<{ data: BasketOfferModel[] }>(
        `${environment.backendUrl}/client/cart`
      )
      .pipe(
        tap((response) => {
          setState((state: BasketStateModel) => {
            state.offers = response.data;
            return state;
          });
        }),
        tap(() => {
          // ha belepes elott volt mar valami a kosarban
          if (this.afterLogin === true && prevState.offers.length > 0) {
            this.afterLogin = false;
            dispatch(
              prevState.offers.map(
                (offer) =>
                  new AddItemToBasketAction(
                    { id: offer.id } as BasketOfferModel,
                    offer.quantity
                  )
              )
            );
          }
        })
      );
  }

  @Action(AddItemToBasketAction)
  @ImmutableContext()
  addItemToBasket(
    { setState, getState }: StateContext<BasketStateModel>,
    { product, count }: AddItemToBasketAction
  ) {
    if (this.store.selectSnapshot(AuthState.isLoggedIn)) {
      const currentState = getState();
      let existProductId = -1;
      // TODO backend a kosarba adas utan ad egy idx-et, es addig azt nem tudjuk :(
      // product.idx === ''
      //   ? -1
      //   : this.findProductById(currentState, product.idx);
      // if (existProductId > -1) {
      if (!isNil(product.idx) && isNumeric(product.idx)) {
        // update
        existProductId = currentState.offers.findIndex(
          (offer) => offer.idx === product.idx
        );
        const offer = currentState.offers[existProductId];
        return this.http
          .post<{ data: BasketOfferModel }>(
            `${environment.backendUrl}/client/cart/quantity/${offer.idx}`,
            { quantity: offer.quantity + count }
          )
          .pipe(
            tap((response) => {
              setState((state: BasketStateModel) => {
                state.offers[existProductId] = response.data;
                return state;
              });
              this.googleEventAddToCart(product);
              this.tiktokEventAddToCart(product);
            })
          );
      } else {
        // create
        return this.http
          .post<{ data: BasketOfferModel }>(
            `${environment.backendUrl}/client/cart`,
            { packageId: product.id, quantity: count }
          )
          .pipe(
            tap((response) => {
              setState((state: BasketStateModel) => {
                state.offers.push(response.data);
                return state;
              });
              this.googleEventAddToCart(product);
              this.tiktokEventAddToCart(product);
            })
          );
      }
    }
    else {
      return setState((state: BasketStateModel) => {
        const existProductId = this.findProductById(state, product.idx);
        if (existProductId > -1) {
          const p = state.offers[existProductId];
          state.offers[existProductId] = {
            ...p,
            quantity: p.quantity + count,
            finalPrice: p.finalPrice + p.price * count,
          };
        }
        else {
          product.idx = uuidv4();
          product.finalPrice = product.price * count;
          state.offers.push(product);
        }
        this.googleEventAddToCart(product);
        this.tiktokEventAddToCart(product);
        return state;
      });
    }
  }

  private googleEventAddToCart(product: BasketOfferModel) {
    console.log('------- google add to cart');
    const event = new AddToCartEvent({
      name: product.voucherTitle,
      id: product.id,
      price: product.finalPrice,
      quantity: 1,
      brand: '',
      category: '',
    });
    this.trackService.track(event);
  }

  private tiktokEventAddToCart(product: BasketOfferModel) {
    const event = new AddToCartTiktokEvent({
      name: product.voucherTitle,
      id: product.id,
      price: product.finalPrice,
      brand: '',
      category: '',
      quantity: 1,
      type: 'product',
      currency: 'HUF',
    });
    this.tiktokTrackService.track(event);
  }

  private googleEventRemoveFromCart(product: BasketOfferModel, count: number) {
    const event = new RemoveFromCartEvent({
      name: product.voucherTitle,
      id: product.id,
      price: product.finalPrice,
      quantity: count,
    });
    this.trackService.track(event);
  }

  @Action(RemoveItemFromBasketAction)
  @ImmutableContext()
  removeItemFromBasket(
    { setState, getState }: StateContext<BasketStateModel>,
    { idx, count }: RemoveItemFromBasketAction
  ) {
    if (this.store.selectSnapshot(AuthState.isLoggedIn)) {
      const currentState = getState();
      const existProductId = this.findProductById(currentState, idx);
      if (existProductId === -1) {
        console.error('Not found basket product: ', idx);
        return;
      }
      else {
        const offer = currentState.offers[existProductId];
        if (offer.quantity - count === 0) {
          return this.http
            .delete(`${environment.backendUrl}/client/cart/${offer.idx}`)
            .pipe(
              tap(() => {
                setState((state: BasketStateModel) => {
                  state.offers.splice(existProductId, 1);
                  return state;
                });
              })
            );
        }
        else {
          return this.http
            .post<{ data: BasketOfferModel }>(
              `${environment.backendUrl}/client/cart/quantity/${offer.idx}`,
              { quantity: offer.quantity - count }
            )
            .pipe(
              tap((response) => {
                setState((state: BasketStateModel) => {
                  state.offers[existProductId] = response.data;
                  return state;
                });
                this.googleEventRemoveFromCart(offer, count);
                // TODO: TIKTOK track RemoveFromCart
              })
            );
        }
      }
    }
    else {
      return setState((state: BasketStateModel) => {
        const existProductId = this.findProductById(state, idx);
        if (existProductId === -1) {
          console.error('Not found basket product: ', idx);
        }
        else {
          const p = state.offers[existProductId];
          state.offers[existProductId] = {
            ...p,
            quantity: p.quantity - count,
            finalPrice: p.finalPrice - p.price * count,
          };
          this.googleEventRemoveFromCart(state.offers[existProductId], count);
          // TODO: TIKTOK track RemoveFromCart
          if (state.offers[existProductId].quantity === 0) {
            state.offers.splice(existProductId, 1);
          }
        }
        return state;
      });
    }
  }

  @Action(ClearBasketAction)
  @ImmutableContext()
  clearBasket({ setState }: StateContext<BasketStateModel>) {
    setState((state: BasketStateModel) => {
      state.offers = [];
      state.coupon = null;
      state.overviewForm = { gifts: {}, couponCode: '' };
      state.addressForm = null;
      return state;
    });
  }

  @Action(RemoveCouponAction)
  @ImmutableContext()
  removeCoupon({ setState }: StateContext<BasketStateModel>) {
    setState((state: BasketStateModel) => {
      state.coupon = null;
      return state;
    });
  }

  private findProductById(state: BasketStateModel, idx: string): number {
    return state.offers.findIndex((p) => p.idx === idx);
  }

  @Action(SaveBasketOverviewFormAction)
  @ImmutableContext()
  saveBasketOverviewForm(
    { setState }: StateContext<BasketStateModel>,
    { form }: SaveBasketOverviewFormAction
  ) {
    setState((state: BasketStateModel) => {
      state.overviewForm = form;
      return state;
    });
  }

  @Action(SaveBasketAddressFormAction)
  @ImmutableContext()
  saveBasketAddressForm(
    { setState }: StateContext<BasketStateModel>,
    { form }: SaveBasketAddressFormAction
  ) {
    setState((state: BasketStateModel) => {
      state.addressForm = form;
      return state;
    });
  }

  @Action(InitBasketAction)
  @ImmutableContext()
  initBasket(
    { getState }: StateContext<BasketStateModel>,
    { step }: InitBasketAction
  ) {
    const state = getState();
    const currentUser = this.store.selectSnapshot(AuthState.currentUser);
    const products = state.offers.map((offer) => ({
      name: offer.voucherTitle,
      id: offer.id,
      price: offer.finalPrice,
      quantity: offer.quantity,
    }));
    const event = new CheckoutEvent(currentUser?.userId, step, products);

    this.trackService.track(event);
  }

  @Action(ValidateBasketCouponAction)
  @ImmutableContext()
  validateCoupon(
    { setState }: StateContext<BasketStateModel>,
    { code }: ValidateBasketCouponAction
  ): void {
    this.http
      .get<{
        success: boolean;
        data: BasketCouponModel;
        error: string;
      }>(`${environment.backendUrl}/client/coupon/${code}`)
      .subscribe((res) => {
        if (res.success) {
          setState((state: BasketStateModel) => {
            state.coupon = res.data;
            return state;
          });
        } else {
          this.openCouponCodeErrorPopup();
        }
      });
  }

  @Action(LogoutAction)
  @ImmutableContext()
  logout({ setState }: StateContext<BasketStateModel>) {
    setState((state: BasketStateModel) => {
      return deepCopy(defaultState);
    });
  }

  private openCouponCodeErrorPopup() {
    this.matSnackBar.open('Érvénytelen kupon kód', undefined, {
      panelClass: ['snackBarStyleForError'],
      duration: 2000,
    });
  }
}
