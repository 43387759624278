import { EventProductTiktok } from '../models/event-product.model';
import { TiktokEvent } from '../models/tiktok-event.model';
import { TiktokEventType } from '../models/tiktok-event-type.model';
import { TiktokEventBody } from '../models/tiktok-event-body.model';
import { TiktokEventContent } from '../models/tiktok-event-content.model';


export class PaymentTiktokEvent implements TiktokEvent {
    public eventType: TiktokEventType;

    products: EventProductTiktok[];

    constructor(pageType: TiktokEventType, products: EventProductTiktok[]) {
        this.eventType = pageType;
        this.products = products;
    }

    getEvent(): TiktokEventBody {

        const revenue = this.products.reduce((prev, curr) => {
            prev += curr.price;
            return prev;
        }, 0);

        let tiktokProducts: TiktokEventContent[] = this.products.map((product) => ({
            content_id: product.id.toString(),
            content_type: 'product',
            content_name: product.name,
        }));

        return {
            value: revenue,
            currency: 'HUF',
            contents: tiktokProducts
        };
    }
}

